<template>
  <div id="testimonial-section-container">
    <div :key="testimonial.id" v-for="(testimonial, index) in testimonialList">
      <testimonial-item v-bind:testimonial="testimonial" :direction="index" />
    </div>
  </div>
</template>
<script>
import TestimonialItem from "./TestimonialItem.vue";
export default {
  name: "testimonial-section",
  components: {
    TestimonialItem,
  },
  data() {
    return {
      index: 0,
      testimonialList: [
        {
          id: 1,
          personName: "Person A",
          jobTitle: "CEO",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
          imgName: "background",
        },
        {
          id: 2,
          personName: "Person B",
          jobTitle: "CEO",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
          imgName: "background",
        },
        {
          id: 3,
          personName: "Person C",
          jobTitle: "CEO",
          content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
          imgName: "background",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
#testimonial-section-container {
  background-color: #aec99c;
}
</style>
