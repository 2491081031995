<template>
  <div class="testimonial-item-container">
    <picture class="picture-tag-outer">
      <!-- <source :srcset ="require(`@/assets/${testimonial.imgName}.webp`)" type="image/webp" /> -->
      <!-- <source :srcset ="require(`@/assets/${product.imgName}.jpeg`)" type="image/jpeg" /> -->
      <img
        class="profile-pic"
        :src="require(`@/assets/images/backgrounds/${testimonial.imgName}.jpg`)"
        alt="testimonial profile picture"
      />
    </picture>
    <div
      class="testimonial-container"
      :class="[{ even: !(direction % 2) }, { odd: direction % 2 }]"
    >
      <div class="comma top">"</div>

      <div class="testimonial-content-container">
        <div class="testimonial-content">
          <p class="testimonial">{{ testimonial.content }}</p>
          <picture class="picture-tag">
            <!-- <source :srcset ="require(`@/assets/${testimonial.imgName}.webp`)" type="image/webp" /> -->
            <!-- <source :srcset ="require(`@/assets/${product.imgName}.jpeg`)" type="image/jpeg" /> -->
            <img
              class="profile-pic"
              :src="
                require(`@/assets/images/backgrounds/${testimonial.imgName}.jpg`)
              "
              alt="testimonial profile picture"
            />
          </picture>
        </div>

        <div class="testimonial-sign-off">
          <span class="testimonial-person-name">
            {{ testimonial.personName }}
          </span>
          <p>{{ testimonial.jobTitle }}</p>
        </div>
      </div>
      <div class="comma bottom">"</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "testimonial-item",
  props: ["testimonial", "direction"],
};
</script>
<style lang="scss" scoped>
.testimonial-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0 70px 0;
}
.testimonial-container {
  position: relative;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.testimonial-content-container {
  display: flex;
  flex-direction: column;
  width: 70vw;
}

.testimonial-content {
  padding-top: 5vw;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 70vw;
  align-self: center;
}

.comma {
  color: grey;
  font-size: 11rem;
  height: 100px;
  width: 100px;
}

.top {
  align-self: flex-start;
}
.bottom {
  align-self: flex-end;
}

.odd {
  .testimonial-content {
    flex-direction: row-reverse;
  }
  .testimonial-sign-off {
    width: 85%;
    padding-left: 15%;
    text-align: left;
    p {
      margin-bottom: 0;
    }
  }
}

.even {
  .testimonial-content {
    flex-direction: row;
  }
  .testimonial-sign-off {
    width: 85%;
    text-align: left;
    p {
      margin-bottom: 0;
    }
  }
}

.testimonial-person-name {
  font-weight: bold;
}

.picture-tag {
  width: 15%;
  .profile-pic {
    height: 7vw;
    width: 7vw;
    border: white solid 4px;
    border-radius: 50%;
  }
}

.picture-tag-outer {
  .profile-pic {
    height: 80px;
    width: 80px;
    border: white solid 4px;
    border-radius: 50%;
    float: left;
  }
}

.testimonial {
  width: 85%;
  text-align: left;
}

@media only screen and (max-width: 1024px) {
  .testimonial-container {
    font-size: 1em !important;
  }
  .testimonial-item-container {
    padding: 30px 0;
  }
}
@media only screen and (max-width: 768px) {
  .picture-tag {
    display: none;
  }
  .testimonial {
    width: 100%;
    text-align: center;
  }
  .odd .testimonial-sign-off {
    width: 100%;
    padding: 0;
    text-align: center;
  }
  .even .testimonial-sign-off {
    width: 100%;
    text-align: center;
  }

  .comma {
    font-size: 5rem;
    height: 50px;
    width: 50px;
  }
}
@media only screen and (min-width: 768px) {
  .picture-tag-outer {
    display: none;
  }
}
</style>
