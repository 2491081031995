<template>
  <div id="content">
    <testimonial-hero />
    <testimonial-section />
  </div>
</template>

<script>
// @ is an alias to /src
import TestimonialHero from "@/components/TestimonialPage/TestimonialHero.vue";
import TestimonialSection from "@/components/TestimonialPage/TestimonialSection.vue";

export default {
  name: "Home",
  components: {
    TestimonialHero,
    TestimonialSection,
  },
  metaInfo: {
    title: "Testimonials ",
    titleTemplate:
      "%s |  Alpha Global Wellness| some copywriting regarding the products overall",
  },
};
</script>
<style lang="scss" scoped></style>
