<template>
  <div id="testimonial-hero-container">
    <b id="testimonial-hero-title">Customer Testimonials</b>
    <p id="testimonial-hero-description">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit
      amet, consectetur adipiscing elit, sed do eiusmod
    </p>
  </div>
</template>
<script>
export default {
  name: "testimonial-hero",
};
</script>
<style lang="scss" scoped>
#testimonial-hero-container {
  background-color: #e8cf8f;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-top: 100px;
  padding-bottom: 10px;
}
#testimonial-hero-title {
  font-size: 3rem;
  margin: 0px 0px 10px 0px;
}
#testimonial-hero-description {
  width: 800px;
  font-size: 1.3rem;
}
@media only screen and (max-width: 1024px) {
  #testimonial-hero-container {
    min-height: 40vh;
  }
  #testimonial-hero-title {
    font-size: 2rem !important;
  }
  #testimonial-hero-description {
    width: 60vw;
    font-size: 1.2rem !important;
  }
}
@media only screen and (max-width: 450px) {
  #testimonial-hero-title {
    font-size: 1.5rem !important;
  }
  #testimonial-hero-description {
    width: 60vw;
    font-size: 1rem !important;
  }
}
</style>
